'use client';

import { createContext, useState, useEffect, useCallback, useMemo } from 'react';
import { usePathname } from 'next/navigation';

const UIContext = createContext(null);

//* Global Variables
const errorsInitialState = {
	formError: {
		error: false,
		msg: '',
	},
	systemError: {
		error: false,
		msg: '',
	},
};

const UIProvider = (props) => {
	const pathname = usePathname();

	//! States
	const [state, setState] = useState({
		popupIsOpen: false,
		highComponent: null,
		popupComponent: null,
		loading: false,
		queryObj: '',
		preloader: true,
		winWidth: 1920,
		winHeight: 1080,
		screenSizes: {
			screen3XL: 2560,
			screen2XL: 1920,
			screenXL: 1440,
			screenL: 1366,
			screenM: 1280,
			screenS: 1024,
			screenXS: 768,
		},
		colors: {},
		darkMode: false,
		lightHeader: false,
		globalData: props.globalData,
		...errorsInitialState,
	});

	//! Is Mobile
	const isMobile = useMemo(() => {
		if (typeof window === 'undefined') return;
		return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || 'ontouchstart' in window || (navigator.maxTouchPoints && navigator.maxTouchPoints > 0);
	}, []);

	//! Is Apple
	const isApple = useMemo(() => {
		if (typeof window === 'undefined') return;

		const ua = navigator.userAgent;
		const isMac = /Mac/i.test(ua);
		const isIOS = /iPhone|iPad|iPod/i.test(ua);

		const isIPadOnMac = isMac && navigator.maxTouchPoints > 1;

		return isIOS || isMac || isIPadOnMac;
	}, []);

	//! Screen Resize
	const screenResize = useCallback(() => {
		setState((prevState) => ({
			...prevState,
			winWidth: window.innerWidth,
			winHeight: window.innerHeight,
		}));
	}, []);

	//! Get Media Screen Sizes from Css Variables
	const getScreenSizes = useCallback(() => {
		const root = getComputedStyle(document.documentElement);

		const screenSizes = {
			screen3XL: parseInt(root.getPropertyValue('--desktopSizeXL')),
			screen2XL: parseInt(root.getPropertyValue('--desktopSizeL')),
			screenXL: parseInt(root.getPropertyValue('--desktopSizeM')),
			screenL: parseInt(root.getPropertyValue('--desktopSizeS')),
			screenM: parseInt(root.getPropertyValue('--desktopSizeXS')),
			screenS: parseInt(root.getPropertyValue('--tabletSize')),
			screenXS: parseInt(root.getPropertyValue('--tabletSizeS')),
		};

		const colors = {
			black: root.getPropertyValue('--black').trim(),
			white: root.getPropertyValue('--white').trim(),
			color1: root.getPropertyValue('--color1').trim(),
			color2: root.getPropertyValue('--color2').trim(),
		};

		setState((prevState) => ({
			...prevState,
			screenSizes,
			colors,
		}));
	}, []);

	const disablePreloader = () => {
		setState((prevState) => ({
			...prevState,
			preloader: false,
		}));
	};

	//! Toggle Light/Dark Header
	const toggleLightHeader = useCallback((light = false) => {
		setState((prevState) => ({
			...prevState,
			lightHeader: light,
		}));
	}, []);

	//! Check Not Found Page
	const checkNotFoundPage = useCallback(() => {
		if (pathname === '/404') {
			disablePreloader();
		}
	}, [pathname]);

	//! Add Error Message
	const addErrorMsg = useCallback((type, msg) => {
		type === 'form'
			? setState((prevState) => ({
					...prevState,
					formError: {
						error: true,
						msg,
					},
			  }))
			: setState((prevState) => ({
					...prevState,
					systemError: {
						error: true,
						msg,
					},
			  }));
	}, []);

	//! Remove Error Message
	const removeErrorMsg = useCallback((type) => {
		type === 'form'
			? setState((prevState) => ({
					...prevState,
					formError: {
						error: false,
						msg: '',
					},
			  }))
			: setState((prevState) => ({
					...prevState,
					systemError: {
						error: false,
						msg: '',
					},
			  }));
	}, []);

	//! Add High Component
	const addHighComponent = useCallback((component = null) => {
		setState((prevState) => ({
			...prevState,
			highComponent: component,
		}));
	}, []);

	//! Remove High Component
	const removeHighComponent = useCallback(() => {
		setState((prevState) => ({
			...prevState,
			highComponent: null,
		}));
	}, []);

	//! Scroll To Top
	const scrollToTop = useCallback((behavior = 'instant') => {
		window.scrollTo({
			top: 0,
			behavior,
		});
	}, []);

	//! Disable DOM Scroll
	const disableDomScroll = useCallback(() => {
		//? document.querySelector('html').classList.add('hide-scroll')

		const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

		//! if any scroll is attempted, set this to the previous value
		window.onscroll = function (e) {
			e.preventDefault();
			window.scrollTo(scrollLeft, scrollTop);
		};
	}, []);

	//! Enable DOM Scroll
	const enableDomScroll = useCallback(() => {
		//? document.querySelector('html').classList.remove('hide-scroll');

		const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

		window.onscroll = function () {};
		window.scrollTo(scrollLeft, scrollTop);
	}, []);

	//! Toggle Dark Mode
	const toggleDarkMode = useCallback(() => {
		setState((prevState) => {
			let root = document.documentElement;
			const isDarkMode = !prevState.darkMode; // Toggle current state

			const color1 = prevState.colors.color1 === prevState.colors.black ? prevState.colors.white : prevState.colors.black;

			const color2 = prevState.colors.color2 === prevState.colors.black ? prevState.colors.white : prevState.colors.black;

			// Apply styles to root element
			root.style.setProperty('--color1', color1);
			root.style.setProperty('--color2', color2);

			return {
				...prevState,
				darkMode: isDarkMode,
				colors: { ...prevState.colors, color1, color2 },
			};
		});

		return true;
	}, []);

	//! Component Did Mount
	useEffect(() => {
		checkNotFoundPage();
		getScreenSizes();
		screenResize();
	}, []);

	//! Component Will Unmount
	useEffect(() => {
		window.addEventListener('resize', screenResize);

		return () => {
			window.removeEventListener('resize', screenResize);
		};
	}, [screenResize]);

	//! Methods
	const methods = {
		addHighComponent,
		removeHighComponent,
		toggleLightHeader,
		addErrorMsg,
		removeErrorMsg,
		disableDomScroll,
		enableDomScroll,
		toggleDarkMode,
		scrollToTop,
		disablePreloader,
	};

	return (
		<UIContext.Provider
			value={{
				...state,
				...methods,
				isApple,
				isMobile,
			}}>
			{props.children}
		</UIContext.Provider>
	);
};

export default UIProvider;
export const UIConsumer = UIContext.Consumer;
